/* //////////////////////////////////////
              TAILWIND
////////////////////////////////////// */


@tailwind base;
@tailwind components;
@tailwind utilities;


.task-list-item::before {
  @apply hidden;
}

.task-list-item {
  @apply list-none;
}

.footnotes {
  @apply pt-8 mt-12 border-t border-gray-200 dark:border-gray-700;
}

.csl-entry {
  @apply my-5;
}

/* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}



@layer base {
    .no a {
      @apply text-blue-700 underline;
    }
    .no p {
      @apply my-4;
    }
    
    .no blockquote,
    .no figure {
      @apply my-4 mx-10;
    }
    
    .no hr {
      @apply border;
    }
    
    .no h1 {
      @apply text-4xl font-bold my-2;
    }
    .no h2 {
      @apply text-2xl font-bold my-3;
    }
    
    .no h3 {
      @apply text-lg font-bold my-4;
    }
    
    .no h4 {
      @apply text-base font-bold my-5;
    }
    
    .no h5 {
      @apply text-sm font-bold my-6;
    }
    
    .no h6 {
      @apply text-xs font-bold my-10;
    }
    
    .no article h1,
    .no aside h1,
    .no nav h1,
    .no section h1 {
      @apply text-2xl font-bold my-3;
      }
    
      .no article h1,
      .no aside h1,
      .no nav h1,
      .no section h1 {
      
        @apply text-lg font-bold my-4;
      }
    
      .no article h1,
      .no aside h1,
      .no nav h1,
      .no section h1 {
         
        @apply text-base font-bold my-5;
        }
    
        .no article h1,
        .no aside h1,
        .no nav h1,
        .no section h1 {
        
          @apply text-sm font-bold my-6;
        }
    
        .no article h1,
        .no aside h1,
        .no nav h1,
        .no section h1 {
          
          @apply text-xs font-bold my-10;
          }

    .no ul,
    .no menu {
      @apply list-disc my-1 pl-10;
    }
    
    .no ol {
      @apply list-decimal my-4 pl-10;
    }
    
    .no ul,
    .no ol ul {
      list-style-type: circle;
      }
    
      .no ul,
      .no ol ul {
        list-style-type: square;
      }
    
      .no dd {
      @apply pl-10;
    }
    
    .no dl {
      @apply my-4;
    }
    
    .no ul,
    .no ol,
    .no menu,
    .no dl {
  
      margin: 0;
      }
    
    
      .no legend {
      @apply py-0 px-1;
    }
    
    .no fieldset {
      @apply my-0 mx-1 pt-0 px-1 pb-2;
    }
    
    .no b,
    .no strong {
      font-weight: bold;
    }
    
    .no pre {
      @apply my-4;
    }
    /* .no h1 {
      @apply 2xs:text-purple-400 2xs:text-[25px] 2xs:leading-[25px];
    } */
    .no * {
      @apply break-words !important;
    }
    .brdr {
      @apply border-b-[0.1px] border-gray-200 !important;
     }
    @media (max-width: 640px) {
     .no * {
      /* @apply text-sm !important; */
      @apply mx-2 !important;

     }
     .no h1{
      @apply text-[25px] leading-[25px];
     }
     .no h2{
      @apply text-[23px] leading-[23px];
     }
     .no h3{
      @apply text-[20px] leading-[20px];
     }
     .no h4{
      @apply text-[18px] leading-[18px];
     }
     .no h5{
      @apply text-[16px] leading-[16px];
     }
     .no h6{
      @apply text-[14px] leading-[14px];
     }
     .no p {
      @apply text-[14px] leading-[20px];

     }
     .no pre {
      @apply text-[14px] leading-[20px];

     }
    }
}